<template>
<CModal
    color="primary"
    :closeOnBackdrop='false'
    :show.sync="showModal"
    >
        <CInput
            label="Page Name"
            placeholder="Enter the page name"
            v-model="pageName"
            :maxLength="21"
            :isValid="isPageNameValid"
            :disabled='loading'
        />
        <p>
            <span>The page name must be between 5 and 20 characters. The page name must start with a letter and must only contain the following characters: letters (a-z), digits (0-9) and hypen (-).</span>
        </p>
        <span>Examples of page names: </span><em>data-protection</em>, <em>imprint</em>, <em>about-us</em>
        <CInputCheckbox label="Treat content as raw HTML" class="mt-2 slight-dim" :checked.sync="isRawHtml" :disabled='loading' />
        <!-- :checked.sync="..."  -->
        <template #header>
            <h5 class="modal-title">Add Content Page</h5>
        </template>
        <template #footer>
            <div v-if="loading" class="mr-auto d-flex align-items-center">
                <CSpinner class="mr-3" /><strong>Creating page ...</strong>
            </div>
            <CButton :disabled='loading' @click="onCancel">Cancel</CButton>
            <CButton :disabled='loading' color="success" @click="onCreate">Create Page</CButton>
        </template>
</CModal>
</template>
<script>
import { actionNames } from '@/store/modules/content/actions';
import { mapActions, mapGetters } from 'vuex';
export default {
    data() {
        return {
            showModal: false,
            pageName: '',
            isRawHtml: false
        }
    },
    computed: {
        ...mapGetters({
            loading: 'content/loading'
        }),
        isPageNameValid() {
            return this.pageName != null && (this.pageName.length > 4) && /^\w[a-z\-0-9]{4,19}$/g.test(this.pageName);
        }
    },
    methods:
    {
        ...mapActions({
            listPages: actionNames.LIST_PAGES,
            createPage: actionNames.CREATE_PAGE
        }),
        show() {
            this.pageName = '';
            this.showModal = true;
            this.isRawHtml = false;
        },
        async createNewPage() {
            try {
                await this.createPage({name:this.pageName, kind: this.isRawHtml ? 'html' : 'delta'});
            }
            catch (e)
            {
                console.log(e);
            }

            this.showModal = false;
            this.$router.push(`/pages/${this.pageName}?kind=${this.isRawHtml ? 'Html' : 'Delta'}`);
        },
        onCancel() {
            this.showModal = false;
        },
        onCreate() {
            this.createNewPage();
        }
    },
    watch: {
        showModal(value) {
            console.log("showModal: " + value);
        }
    }
}
</script>

<style scoped>
.slight-dim {
  opacity: 0.85;
}
</style>
```
