var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',{staticStyle:{"min-height":"120px"}},[_c('NewPageModal',{ref:"newPageModal"}),_c('CCardHeader',{staticClass:"pt-2 pb-2"},[_c('div',{staticClass:"clearfix mb-0 pb-0"},[_c('h4',{staticClass:"pt-1 pb float-left"},[_vm._v("Content Pages")]),(_vm.canEdit)?_c('CButton',{staticClass:"float-right",attrs:{"color":"primary"},on:{"click":_vm.onNewPage}},[_c('CIcon',{staticClass:"mt-0 mb-1",attrs:{"name":"cilPlus"}}),_vm._v(" Add Page")],1):_vm._e()],1)]),_c('CCardBody',{staticClass:"pt-2"},[(_vm.isLoading)?_c('CRow',[_c('CCol',{staticClass:"justify-content-center",attrs:{"lg":"12"}},[_c('CSpinner',{staticClass:"mt-3"})],1)],1):_vm._e(),_c('CAlert',{staticClass:"mt-2 mb-0",attrs:{"show":!_vm.isError && _vm.empty,"color":"info"}},[_vm._v(" No pages configured. ")]),_c('CAlert',{staticClass:"mt-2 mb-0",attrs:{"show":_vm.isError,"color":"danger"}},[_vm._v(" An unknown error occured. Please consider logging out and in again. ")]),(!_vm.empty && !_vm.isLoading)?_c('CDataTable',{attrs:{"items":_vm.items,"items-per-page":100,"loading":_vm.isLoading,"hover":"","addTableClasses":"mb-0 pb-2 mt-3 channels-table","fields":[
                     { key:'name', label: 'Name', _style: 'width: 150px'},
                     { key:'title', label: 'Title' },
                     { key:'kind', label: 'Page Type' },
                     { key:'languages', label: 'Translations', _style: 'width: 300px' },
                     { key:'pageUrl', label:'Link', _style: 'width: 120px;'}
                    ]},scopedSlots:_vm._u([{key:"name",fn:function(ref){
                    var item = ref.item;
return [_c('td',[_c('CLink',{attrs:{"to":("/pages/" + (item.name) + "?kind=" + (item.kind))}},[_vm._v(_vm._s(item.name)),_c('CIcon',{staticClass:"ml-1 mr-0 mb-1",attrs:{"name":"cil-pencil","size":"sm"}})],1)],1)]}},{key:"kind",fn:function(ref){
                    var item = ref.item;
return [_c('td',[(item.kind == 'Html')?_c('span',{staticClass:"content-kind text-info font-weight-500 px-1"},[_vm._v(_vm._s(item.kind))]):_vm._e(),(item.kind == 'Delta')?_c('span',{staticClass:"content-kind text-info font-weight-500 px-1"},[_vm._v("RTF")]):_vm._e()])]}},{key:"languages",fn:function(ref){
                    var item = ref.item;
return [_c('td',[(_vm.hasLanguages)?[_c('CBadge',{staticClass:"p-1 mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"secondary","to":("/pages/" + (item.name))}},[_vm._v("Default")]),_vm._l((_vm.languages),function(lang){return _c('CLink',{key:lang,class:{strong: _vm.hasTranslation(item.languages, lang), 'mr-2': true},attrs:{"to":("/pages/" + (item.name) + "?tl=" + lang + "&kind=" + (item.kind))}},[_vm._v(_vm._s(lang))])})]:[_vm._v("-")]],2)]}},{key:"pageUrl",fn:function(ref){
                    var item = ref.item;
return [_c('td',[_c('span',[_vm._v("View page "),_c('a',{attrs:{"href":item.pageUrl,"target":"_blank"}},[_c('CIcon',{staticClass:"ml-1 mr-0 mb-1",attrs:{"name":"cil-external-link","size":"sm"}})],1)])])]}}],null,false,1927543013)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }