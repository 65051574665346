<template>
<CRow>
    <CCol sm="12">
        <CCard style="min-height: 120px;">
            <NewPageModal ref="newPageModal" />
            <CCardHeader class="pt-2 pb-2">
                <!-- <h4 class="pt-1 pb-0 float-left">Content Pages</h4>   -->
                <div class="clearfix mb-0 pb-0">
                    <h4 class="pt-1 pb float-left">Content Pages</h4>
                    <CButton v-if="canEdit" color="primary" class="float-right" @click="onNewPage"><CIcon name="cilPlus" class="mt-0 mb-1" />&nbsp;Add Page</CButton>
                </div>
            </CCardHeader>
            <CCardBody class="pt-2">
                <CRow v-if='isLoading'>
                    <CCol lg="12" class="justify-content-center">
                        <CSpinner class="mt-3" />
                    </CCol>
                </CRow>
                <CAlert :show="!isError && empty" color="info" class="mt-2 mb-0">
                No pages configured.
                </CAlert>
                 <CAlert :show="isError" color="danger" class="mt-2 mb-0">
                An unknown error occured. Please consider logging out and in again.
                </CAlert>
                <CDataTable
                    v-if="!empty && !isLoading"
                    :items="items"
                    :items-per-page="100"
                    :loading="isLoading"
                    hover
                    addTableClasses="mb-0 pb-2 mt-3 channels-table"
                     :fields="[
                     { key:'name', label: 'Name', _style: 'width: 150px'},
                     { key:'title', label: 'Title' },
                     { key:'kind', label: 'Page Type' },
                     { key:'languages', label: 'Translations', _style: 'width: 300px' },
                     { key:'pageUrl', label:'Link', _style: 'width: 120px;'}
                    ]"
                >
                    <template #name="{item}">
                        <td>
                            <CLink :to="`/pages/${item.name}?kind=${item.kind}`">{{item.name}}<CIcon name="cil-pencil" size="sm" class="ml-1 mr-0 mb-1" /></CLink>
                        </td>
                    </template>
                    <template #kind="{item}">
                        <td>
                            <span v-if="item.kind == 'Html'" class="content-kind text-info font-weight-500 px-1">{{ item.kind }}</span>
                            <span v-if="item.kind == 'Delta'" class="content-kind text-info font-weight-500 px-1">RTF</span>
                        </td>
                      </template>
                    <template #languages="{item}">
                        <td>
                            <template v-if="hasLanguages">
                                <CBadge color="secondary" class="p-1 mr-2" style="cursor: pointer" :to="`/pages/${item.name}`">Default</CBadge>
                                <CLink :to="`/pages/${item.name}?tl=${lang}&kind=${item.kind}`" v-for="lang in languages" :key="lang" :class="{strong: hasTranslation(item.languages, lang), 'mr-2': true}">{{ lang }}</CLink>
                            </template>
                            <template v-else>-</template>
                        </td>
                    </template>
                    <template #pageUrl="{item}">
                        <td>
                            <span>View page <a :href="item.pageUrl" target="_blank"><CIcon name="cil-external-link" size="sm" class="ml-1 mr-0 mb-1" /></a></span>
                        </td>
                    </template>
                </CDataTable>
            </CCardBody>
        </CCard>
    </CCol>
</CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/content/actions';
import NewPageModal from '@/components/modals/NewPageModal';
import { EDIT_CONTENT, mapPermissions } from '@/permissions';

export default {
    components: {
        NewPageModal
    },
    data() {
        return {
            isError: false
        };
    },
    computed: {
        ...mapPermissions({
            canEdit: EDIT_CONTENT
        }),
        ...mapGetters({
            items: 'content/pages',
            isLoading: 'content/loading',
            languages: 'content/languages',
            merchantId: 'merchantId'
        }),
        hasLanguages() {
            return this.languages && this.languages.length > 1;
        },
        empty() {
            return !this.isLoading && this.items && this.items.length == 0;
        }
    },
    methods: {
        ...mapActions({
            loadPages: actionNames.LIST_PAGES
        }),
        async load() {
            this.isError = false;
            try {
                if (this.merchantId != null) {
                    await this.loadPages();
                }
            }
            catch (e)
            {
                this.isError = true;
            }
        },
        /* eslint-disable */
        onNewPage()
        {
            this.$refs.newPageModal.show();
        },
        hasTranslation(languages, lang) {
            return languages.find(l => l == lang);
        }
    },
    created()
    {
        this.load();
    }
}
</script>

<style scoped>
.content-kind {
    text-transform: uppercase;
    font-size: 0.8rem;
    border: 1px solid #c4c9d0;
}
</style>
```
